<template>
  <div class="background">
    <div>
      <div class="title">高校学生图像采集管理系统</div>
      <el-card
        class="backgroundCard"
        shadow="never"
        :bodyStyle="{
          height: 'calc(100% - 80px)',
          padding: '40px',
          backgroundColor: '#F2F6FC',
        }"
      >
        <div class="login">
          <img class="loginImg" src="@/assets/img/userbg1.png" />

          <el-divider class="loginDivider" direction="vertical"></el-divider>

          <div class="loginRight">
            <div class="loginBackground">
              <img src="@/assets/img/userbg44.png" alt="" />
            </div>

            <el-card
              class="loginCard"
              shadow="never"
              :bodyStyle="{ height: 'calc(100% - 40px)' }"
            >
              <div class="loginBody">
                <label class="loginTitle">欢迎登录</label>

                <el-divider></el-divider>

                <el-form :model="loginForm" :rules="loginRules" ref="loginForm">
                  <el-form-item prop="tell">
                    <el-input
                      type="text"
                      prefix-icon="el-icon-user"
                      v-model="loginForm.tell"
                      autocomplete="false"
                      placeholder="手机号"
                    ></el-input>
                  </el-form-item>
                  <el-form-item prop="smsCode">
                    <div style="display: flex">
                      <el-input
                        style="margin-right: 10px"
                        type="text"
                        prefix-icon="el-icon-lock"
                        v-model="loginForm.smsCode"
                        autocomplete="false"
                        placeholder="验证码"
                        @keyup.enter.native="login"
                      >
                      </el-input>

                      <el-popover
                        placement="top"
                        width="330px"
                        trigger="manual"
                        v-model="slideVerifyVisible"
                      >
                        <slide-verify
                          ref="slideblock"
                          :l="42"
                          :r="10"
                          :w="310"
                          :h="155"
                          :imgs="imgs"
                          slider-text="向右滑动"
                          @success="onSuccess"
                        ></slide-verify>
                        <el-button
                          type="primary"
                          plain
                          style="width: 112px"
                          slot="reference"
                          @click="sendSmsCode"
                          :disabled="disable"
                          >{{ buttonMsg }}</el-button
                        >
                      </el-popover>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      style="margin-top: 60px"
                      class="loginButton"
                      type="primary"
                      :loading="loading"
                      @click="login"
                      >登 录
                    </el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-card>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { sendSmsCode } from "@/api/user";

export default {
  name: "Login",
  data() {
    const validateTell = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空"));
      } else {
        callback();
      }
    };
    const validateSmsCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error("验证码不能为空"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        tell: "",
        smsNo: "",
        smsCode: "",
      },
      loginRules: {
        tell: [
          {
            required: true,
            trigger: "blur",
            validator: validateTell,
          },
        ],
        smsCode: [
          {
            required: true,
            trigger: "blur",
            validator: validateSmsCode,
          },
        ],
      },
      loading: false,
      redirect: undefined,
      buttonMsg: "发送验证码",
      count: 60,
      disable: false,
      slideVerifyVisible: false,
      imgs: [
        require("../../assets/img/slide-verify-01.jpg"),
        require("../../assets/img/slide-verify-02.jpg"),
        require("../../assets/img/slide-verify-03.jpg"),
      ],
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$store
            .dispatch("user/login", this.loginForm)
            .then((res) => {
              const roles = res.user.roles.filter(
                (o) => o.code == "邮寄管理员" || "学校管理"
              );
              if (roles.length) {
                this.$router.push({
                  path: res.user.menus[0].path || "/",
                });
                return;
              }
              this.$router.push({
                path: this.redirect || "/",
              });
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    sendSmsCode() {
      this.$refs.loginForm.validateField("tell", (valid) => {
        if (!valid) {
          this.slideVerifyVisible = true;
        } else {
          return false;
        }
      });
    },
    countDown() {
      var countDown = setInterval(() => {
        if (this.count < 1) {
          this.disable = false;
          this.buttonMsg = "发送验证码";
          this.count = 60;
          clearInterval(countDown);
        } else {
          this.disable = true;
          this.buttonMsg = this.count-- + " 秒";
        }
      }, 1000);
    },
    onSuccess() {
      const data = this.loginForm.tell;
      sendSmsCode(data)
        .then((resp) => {
          this.slideVerifyVisible = false;
          this.$refs.slideblock.reset();
          this.$notify({
            title: "发送成功",
            message: "发送短信验证码成功，短信编号：" + resp.data,
            type: "success",
          });
          this.loginForm.smsNo = resp.data;
          this.countDown();
        })
        .catch((error) => {
          this.slideVerifyVisible = false;
          this.$refs.slideblock.reset();
        });
    },
  },
};
</script>

<style scoped>
.background {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../assets/img/userbg3.png);
  background-repeat: round;
}

.title {
  font-size: 50px;
  color: white;
  font-weight: bold;
  text-align: center;
  letter-spacing: 5px;
  margin-bottom: 20px;
}

.backgroundCard {
  width: 80%;
  min-width: 1300px;
  height: 500px;
}

.login {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.loginImg {
  height: 100%;
}

.loginDivider {
  height: 100%;
}

.loginRight {
  display: flex;
  padding: 40px;
}

.loginBackground {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 5px;
}

.loginCard {
  width: 300px;
}

.loginBody {
  height: 100%;
}

.loginTitle {
  font-size: 20px;
  color: #446cf3;
}

.loginButton {
  width: 100%;
}
</style>
